const workItems = [
  {
    id: 1,
    uniqueName: "webcreate01",
    title: "Used car sales site",
    category: "webCreate",
    description: "#",
    skill:
      "Web Design / HTML / CSS / JQuery / Coding / Wordpress / Maintenance and Operation",
    img01: "./workUnico01.png",
    img02: "./workUnico02.png",
    img03: "./workUnico03.png",
    img04: "./workUnico04.png",
    img05: "./workUnicoSp01.png",
    img06: "./workUnicoSp02.png",
    img07: "./workUnicoSp03.png",
    img08: "./workUnicoSp04.png",
    url: "http://unico-auto.com/",
    toplist: true,
  },
  {
    id: 2,
    uniqueName: "webcreate02",
    title: "Branded goods purchase site",
    category: "webCreate",
    description: "#",
    skill:
      "Web Design / HTML / CSS / JQuery / Coding / Wordpress / Maintenance and Operation",
    img01: "./workJunk01.png",
    img02: "./workJunk02.png",
    img03: "./workJunk03.png",
    img04: "./workJunk04.png",
    img05: "./workJunkSp01.png",
    img06: "./workJunkSp02.png",
    img07: "./workJunkSp03.png",
    img08: "./workJunkSp04.png",
    url: "http://junk-lv.com/",
    toplist: true,
  },
  {
    id: 3,
    uniqueName: "webcreate03",
    title: "Human Resources Recruitment Site",
    category: "webCreate",
    description: "#",
    skill:
      "Web Design / HTML / CSS / JQuery / Coding  / writing / Maintenance and Operation",
    img01: "./workTokyo01.png",
    img02: "./workTokyo02.png",
    img03: "./workTokyo03.png",
    img04: "./workTokyo04.png",
    img05: "./workTokyoSp01.png",
    img06: "./workTokyoSp02.png",
    img07: "./workTokyoSp03.png",
    img08: "./workTokyoSp04.png",
    url: "https://bricks.kcforce.jp/",
    toplist: true,
  },
  {
    id: 4,
    uniqueName: "webcreate04",
    title: "Human Resources Recruitment Site",
    category: "webCreate",
    description: "#",
    skill:
      "Web Design / HTML / CSS / JQuery / Coding / writing / Maintenance and Operation",
    img01: "./workSapporo01.png",
    img02: "./workSapporo02.png",
    img03: "./workSapporo03.png",
    img04: "./workSapporo04.png",
    img05: "./workSapporoSp01.png",
    img06: "./workSapporoSp02.png",
    img07: "./workSapporoSp03.png",
    img08: "./workSapporoSp04.png",
    url: "http://www.kskjapan.biz/",
    toplist: false,
  },
  {
    id: 5,
    uniqueName: "webcreate05",
    title: "Used car sales site landing page",
    category: "webCreate",
    description: "#",
    skill:
      "Web Design / HTML / CSS / JQuery / Coding / writing / Wordpress / Maintenance and Operation",
    img01: "./workUnicoLp01.png",
    img02: "./workUnicoLp02.png",
    img03: "./workUnicoLp03.png",
    img04: "./workUnicoLp04.png",
    img05: "./workUnicoLpSp01.png",
    img06: "./workUnicoLpSp02.png",
    img07: "./workUnicoLpSp03.png",
    img08: "./workUnicoLpSp04.png",
    imgArray: [
      "./workUnicoLp01.png",
      "./workUnicoLp02.png",
      "./workUnicoLp03.png",
    ],
    url: "https://unico-auto.com/gclass",
    toplist: true,
  },
  {
    id: 6,
    uniqueName: "webcreate06",
    title: "Major corporate group site",
    category: "webCreate",
    description: "#",
    skill: "HTML / CSS / JQuery / Coding",
    img01: "./workHieng01.png",
    img02: "./workHieng02.png",
    img03: "./workHieng03.png",
    img04: "./workHieng04.png",
    img05: "./workHiengSp01.png",
    img06: "./workHiengSp02.png",
    img07: "./workHiengSp03.png",
    img08: "./workHiengSp04.png",
    url: "https://www.hitachi-ite.co.jp/solution/platform/zerotrust/index.html",
    toplist: false,
  },
  {
    id: 7,
    uniqueName: "react01",
    title: "Trello clone application",
    category: "react",
    description: "#",
    skill: "Web Design / HTML / CSS / Javascript / REACT / Cording",
    img01: "./workTrello01.png",
    img02: "./workTrello02.png",
    img03: "./workTrello03.png",
    img04: "./workTrello04.png",
    // img05: "./workJunkSp01.png",
    // img06: "./workJunkSp02.png",
    // img07: "./workJunkSp03.png",
    // img08: "./workJunkSp04.png",
    url: "https://simple-trello-sigma.vercel.app/",
    toplist: true,
  },
  {
    id: 8,
    uniqueName: "react02",
    title: "Twitter clone application",
    category: "react",
    description: "#",
    skill: "Web Design / HTML / CSS / Javascript / REACT / Cording",
    img01: "./workTwitter01.png",
    img02: "./workTwitter02.png",
    img03: "./workTwitter03.png",
    img04: "./workTwitter04.png",
    // img05: "./workJunkSp01.png",
    // img06: "./workJunkSp02.png",
    // img07: "./workJunkSp03.png",
    // img08: "./workJunkSp04.png",
    url: "https://twitter-clone-ddbb8.web.app/",
    toplist: true,
  },
  {
    id: 9,
    uniqueName: "react03",
    title: "Todo application",
    category: "react",
    description: "#",
    skill: "Web Design / HTML / CSS / Javascript / REACT / Cording",
    img01: "./workTodo01.png",
    img02: "./workTodo02.png",
    img03: "./workTodo03.png",
    img04: "./workTodo04.png",
    // img05: "./workJunkSp01.png",
    // img06: "./workJunkSp02.png",
    // img07: "./workJunkSp03.png",
    // img08: "./workJunkSp04.png",
    url: "https://todo-app01.vercel.app/",
    toplist: false,
  },
  {
    id: 10,
    uniqueName: "react04",
    title: "Visitor Reception System",
    category: "react",
    description: "#",
    skill:
      "Web Design / HTML / CSS / Javascript / REACT / Cording / Web Strage",
    img01: "./workReception01.png",
    img02: "./workReception02.png",
    img03: "./workReception03.png",
    img04: "./workReception04.png",
    // img05: "./workJunkSp01.png",
    // img06: "./workJunkSp02.png",
    // img07: "./workJunkSp03.png",
    // img08: "./workJunkSp04.png",
    url: "https://reception-react01.vercel.app/",
    toplist: true,
  },
  {
    id: 11,
    uniqueName: "react05",
    title: "Visitor Reception(NEXT.js)",
    category: "react",
    description: "#",
    skill:
      "Web Design / HTML / CSS / Javascript / REACT / NEXT.js / Cording / Web Strage",
    img01: "./workReceptionNext01.png",
    img02: "./workReceptionNext02.png",
    img03: "./workReceptionNext03.png",
    img04: "./workReceptionNext04.png",
    // img05: "./workJunkSp01.png",
    // img06: "./workJunkSp02.png",
    // img07: "./workJunkSp03.png",
    // img08: "./workJunkSp04.png",
    url: "https://reception-next01.vercel.app/",
    toplist: true,
  },
  {
    id: 12,
    uniqueName: "react06",
    title: "Pokemon List",
    category: "react",
    description: "#",
    skill: "Web Design / HTML / CSS / Javascript / REACT / Cording / API",
    img01: "./workPokemon01.png",
    img02: "./workPokemon02.png",
    img03: "./workPokemon03.png",
    img04: "./workPokemon04.png",
    // img05: "./workJunkSp01.png",
    // img06: "./workJunkSp02.png",
    // img07: "./workJunkSp03.png",
    // img08: "./workJunkSp04.png",
    url: "https://pokemon-app-mu-five.vercel.app/",
    toplist: true,
  },
  {
    id: 13,
    uniqueName: "react07",
    title: "REACT Cart App",
    category: "react",
    description: "#",
    skill: "Web Design / HTML / CSS / Javascript / REACT / REDUX / Cording",
    img01: "./workReduxShop01.png",
    img02: "./workReduxShop02.png",
    img03: "./workReduxShop03.png",
    img04: "./workReduxShop04.png",
    // img05: "./workJunkSp01.png",
    // img06: "./workJunkSp02.png",
    // img07: "./workJunkSp03.png",
    // img08: "./workJunkSp04.png",
    url: "https://redux-shop-plum.vercel.app/",
    toplist: true,
  },
];

export default workItems;
