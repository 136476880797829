import { Work } from "../Components/Work";
import { Works } from "../Components/Works";

export const WorksRouter = [
  {
    path: "/",
    exact: true,
    children: <Works />,
  },
  {
    path: "/:uniqueName",
    exact: false,
    children: <Work />,
  },
];
