import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { Router } from "./router/Router";
import styled from "styled-components";

function App() {
  return (
    <>
      <All className="all">
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </All>
    </>
  );
}
const All = styled.div`
  /* background-image: url(./bg04.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; */
  height: 100vh;
`;

export default App;
