import React from "react";
import aboutUsItems from "../aboutUsItems";
import styled from "styled-components";
import { Header } from "./Header";
import {
  flexSb,
  width,
  handsomeBold,
  handsomeNomal,
  textColor,
} from "../MixIn";

// const initialState = {
//   aboutUsItems: aboutUsItems,
// };
export const AboutUs = () => {
  console.log(aboutUsItems);
  return (
    <AboutUsContent>
      <Header />
      <div className="content">
        <div className="grid">
          <ul className="about">
            {aboutUsItems.map((item) => {
              return (
                <li>
                  <img src={item.img} className="img" />
                  <div className="descriptions">
                    <p className="title">{item.title}</p>
                    <p className="description">{item.description}</p>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <FooterContent>
        <p>May the world be at peace tomorrow by shonanGold</p>
      </FooterContent>
    </AboutUsContent>
  );
};

const AboutUsContent = styled.div`
  background-image: url(../bg01.png);
  background-position: center center;
  background-repeat: repeat;
  background-size: contain;
  .content {
    padding: 50px 0 30px 0;
    .grid {
      ${width}
      height: 100%;
      margin: 0 auto;
      ul {
        margin: 0px 0 80px 0;
        li {
          ${flexSb}
          padding: 0px 0 100px 0;
          &:nth-child(even) {
            flex-direction: row-reverse;
            .descriptions {
              .title {
                text-align: left;
              }
              .description {
                text-align: left;
              }
            }
          }
          img {
            width: 50%;
          }
          .descriptions {
            width: 45%;
            .title {
              ${handsomeBold}
              font-size: 70px;
              text-align: right;
              ${textColor}
              margin: 0;
            }
            .description {
              ${handsomeNomal}
              font-size: 20px;
              text-align: right;
              margin: 0;
              ${textColor}
            }
          }
        }
      }
    }
  }
`;
const FooterContent = styled.footer`
  padding: 10px 0 30px 0;
  p {
    text-align: center;
    color: white;
    margin: 0;
  }
`;
