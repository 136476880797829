import React from "react";
import styled from "styled-components";
import { Header } from "./Header";
import {
  flex,
  flexSb,
  handsomeNomal,
  width,
  handsomeBold,
  textColor,
} from "../MixIn";

export const Home = () => {
  return (
    <>
      <HomeContent>
        <Header />
        <div className="content">
          <div className="grid">
            <img src="./imgTop.png" alt="" className="eye" />
            <div className="titles">
              <p className="job">Web Engineer</p>
              <p className="name">shonanGold</p>
              <p className="job">-Portfolio Sites-</p>
              <ul className="skill">
                <li>
                  <img src="./html.png" />
                </li>
                <li>
                  <img src="./css.png" />
                </li>
                <li>
                  <img src="./js.png" />
                </li>
                <li>
                  <img src="./jquery.png" />
                </li>
                <li>
                  <img src="./wordpress.png" />
                </li>
                <li>
                  <img src="./php.png" />
                </li>
                <li>
                  <img src="./react.png" />
                </li>
                <li>
                  <img src="./redux.png" />
                </li>
                <li>
                  <img src="./firebase.png" />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <FooterContent>
          <p>May the world be at peace tomorrow by shonanGold</p>
        </FooterContent>
      </HomeContent>
    </>
  );
};
const HomeContent = styled.main`
  background-image: url(../bg04.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  .content {
    padding: 50px 0 30px 0;
    .grid {
      ${width}
      height: 100%;
      margin: 0 auto;
      ${flexSb}
      .eye {
        width: 55%;
      }
      .titles {
        width: 45%;
        ${textColor}
        .job {
          ${handsomeNomal}
          font-size: 30px;
          text-align: right;
          margin: 0;
        }
        .name {
          ${handsomeBold}
          font-size: 75px;
          text-align: right;
          margin: 0;
        }
        .skill {
          ${flex}
          justify-content: flex-end;
          li {
            margin-right: 10px;
            &:last-child {
              margin-right: 0;
            }
            img {
              width: 30px;
            }
          }
        }
      }
    }
  }
`;
const FooterContent = styled.footer`
  padding: 10px 0 30px 0;
  p {
    text-align: center;
    ${textColor}
    margin: 0;
  }
`;
