const aboutUsItems = [
  {
    id: 1,
    title: "Life",
    description: "Archery at the range on holidays. Sometimes running.",
    img: "./imgWork03.png",
  },
  {
    id: 2,
    title: "Create and learn",
    description:
      "Whether designing or coding, creating something is fun. I have a lot of questions every day, but I also enjoy researching them.",
    img: "./imgWork01.png",
  },
  {
    id: 3,
    title: "Active",
    description:
      "I like to travel, both domestically and internationally. Where shall we go next?",
    img: "./imgWork02.png",
  },
  {
    id: 4,
    title: "Dream",
    description: "I would like to work abroad. For example, Germany.",
    img: "./imgWork04.png",
  },
];

export default aboutUsItems;
