import { Switch, Route } from "react-router-dom";
import { AboutUs } from "../Components/AboutUs";
import { Contact } from "../Components/Contact";
import { Home } from "../Components/Home";

import { WorksRouter } from "./WorksRouter";

export const Router = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route path="/aboutus">
        <AboutUs />
      </Route>
      <Route
        path="/works"
        render={({ match: { url } }) => (
          <Switch>
            {/* <Route exact path={url}>
              <Works />
            </Route>
            <Route path={`${url}/work`}>
              <Work />
            </Route> */}

            {WorksRouter.map((route) => (
              <Route
                key={route.path}
                exact={route.exact}
                path={`${url}${route.path}`}
              >
                {route.children}
              </Route>
            ))}
          </Switch>
        )}
      />
      <Route path="/contact">
        <Contact />
      </Route>
    </Switch>
  );
};
