import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Header } from "./Header";
import workItems from "../workItems";
import {
  width,
  flexSb,
  handsomeNomal,
  handsomeBold,
  textColor,
} from "../MixIn";

export const Works = () => {
  const createList = workItems.filter((work) => work.category === "webCreate");
  const reactList = workItems.filter((work) => work.category === "react");
  const otherList = workItems.filter((work) => work.category === "otherWorks");
  return (
    <WorksContent>
      <Header />
      <div className="content">
        <div className="section">
          <div className="grid">
            <WebCreate className="webCreate">
              {createList.map((work) => {
                return (
                  <li key={work.id}>
                    <Link to={`/works/${work.uniqueName}`}>
                      <img src={work.img01} className="img" />
                      <p className="title">{work.title}</p>
                    </Link>
                  </li>
                );
              })}
            </WebCreate>
            <div className="workNameBox">
              <p className="workSubName">WEB Coder & Designer</p>
              <p className="workName">Web Create</p>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="grid reverse">
            <WebCreate className="webCreate">
              {reactList.map((work) => {
                return (
                  <li key={work.id}>
                    <a href={work.url} target="_blank">
                      <img src={work.img01} className="img" />
                      <p className="title">{work.title}</p>
                    </a>
                  </li>
                );
              })}
            </WebCreate>
            <div className="workNameBox reverse">
              <p className="workSubName">Front End Engineer</p>
              <p className="workName">REACT</p>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="grid">
            <WebCreate className="webCreate">
              {otherList.map((work) => {
                return (
                  <li key={work.id}>
                    <Link to={`/works/${work.uniqueName}`}>
                      <img src={work.img01} className="img" />
                      <p className="title">{work.title}</p>
                    </Link>
                  </li>
                );
              })}
            </WebCreate>
            <div className="workNameBox">
              <p className="workSubName"></p>
              <p className="workName">Other Works</p>
            </div>
          </div>
        </div>
      </div>
      <FooterContent>
        <p>May the world be at peace tomorrow by shonanGold</p>
      </FooterContent>
    </WorksContent>
  );
};

const WorksContent = styled.div`
  background-image: url(../bg02.png);
  background-position: top center;
  background-repeat: repeat;
  background-size: contain;
  .content {
    padding: 50px 0 30px 0;
    .grid {
      ${width}
      height: 100%;
      margin: 0 auto;
      ${flexSb}
      &.reverse {
        ${flexSb}
        flex-direction: row-reverse;
      }

      .workNameBox {
        width: 40%;
        &.reverse {
          .workSubName {
            text-align: left;
          }
          .workName {
            text-align: left;
          }
        }
        .workSubName {
          ${handsomeNomal}
          font-size: 30px;
          text-align: right;
          ${textColor}
          margin: 0;
        }
        .workName {
          ${handsomeBold}
          font-size: 70px;
          text-align: right;
          ${textColor}
          margin: 0;
        }
      }
    }
  }
`;

const FooterContent = styled.footer`
  padding: 10px 0 30px 0;
  p {
    text-align: center;
    color: white;
    margin: 0;
  }
`;
const WebCreate = styled.ul`
  width: 55%;
  ${flexSb}
  flex-wrap: wrap;
  li {
    margin-bottom: 20px;
    &:hover {
      opacity: 0.6;
      transition: 0.2s;
    }
    img {
      width: 260px;
    }
    .title {
      margin: 0;
      ${textColor}
      word-break: break-all;
    }
  }
`;
