import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import styled from "styled-components";
import { Header } from "./Header";
import {
  flexSb,
  width,
  handsomeBold,
  handsomeNomal,
  textColor,
} from "../MixIn";

export const Contact = () => {
  const form = useRef();

  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [message, setMessage] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        // サービスID
        "service_fcu7icg",
        // テンプレートID
        "coolcode_shonangold96",
        form.current,
        // ユーザーID
        "lgRqoYJm72lmTIwRd"
      )
      .then(
        (result) => {
          alert("Your email was sent correctly.");
          console.log("成功しました");
        },
        (error) => {
          alert("Failed to send email.");
          console.log("失敗しました");
        }
      );
    setName("");
    setMail("");
    setMessage("");
  };

  const disableSend = name === "" || mail === "" || message === "";

  return (
    <ContactContent>
      <Header />
      <div className="content">
        <div className="grid">
          <form ref={form} onSubmit={sendEmail}>
            <ul className="contact">
              <li>
                <p className="title">Contact</p>
                <hr />
              </li>

              <li className="second">
                <div className="innerBlock">
                  <label>Name</label>
                  <input
                    type="text"
                    name="user_name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Your name"
                  />
                </div>
                <div className="innerBlock">
                  <label>Email</label>
                  <input
                    type="email"
                    name="user_email"
                    value={mail}
                    onChange={(e) => setMail(e.target.value)}
                    placeholder="Your Email address"
                  />
                </div>
              </li>
              <li className="third">
                <div className="innerBlock">
                  <label>Message</label>
                  <textarea
                    name="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Please write your message"
                  />
                </div>
              </li>
              <li>
                <input
                  className="button"
                  type="submit"
                  value="Send"
                  disabled={disableSend}
                />
              </li>
            </ul>
          </form>
        </div>
      </div>
      <FooterContent>
        <p>May the world be at peace tomorrow by shonanGold</p>
      </FooterContent>
    </ContactContent>
  );
};
const ContactContent = styled.div`
  background-image: url(../bg04.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  .content {
    padding: 50px 0 30px 0;
    .grid {
      ${width}
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      .contact {
        width: 750px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        li {
          width: 45%;
          margin-bottom: 50px;
          .innerBlock {
            label {
              display: block;
              ${textColor}
              margin: 30px 0 20px 0;
              font-size: 20px;
            }
            input {
              width: 97%;
              border: none;
              outline: none;
              border-radius: 10px;
              height: 55px;
              font-size: 20px;
              padding: 5px 8px;
            }
            textarea {
              width: 100%;
              border: none;
              outline: none;
              border-radius: 10px;
              height: 300px;
              font-size: 20px;
              padding: 5px 8px;
            }
          }

          &:first-child {
            width: 100%;
            display: flex;
            flex-direction: column;
            text-align: center;
            margin-bottom: 60px;
            .title {
              ${handsomeBold}
              font-size: 45px;
              ${textColor}
              margin: 0;
            }
            hr {
              background-color: white;
              width: 750px;
            }
          }
          &:last-child {
            width: 100%;
          text-align: center;
          .button {
            padding: 20px 0;
            border-radius: 10px;
            width: 300px;
            font-size: 35px;
            ${handsomeBold}
            ${textColor}
          background-color: darkgray;
            box-shadow: 2px 3px 6px silver;
            &:hover {
              transform: translate(2px, 3px);
              box-shadow: none;
              transition: 0.2s;
            }
          
          }
        }
      }
    }
  }
`;
const FooterContent = styled.footer`
  padding: 10px 0 30px 0;
  p {
    text-align: center;
    color: white;
    margin: 0;
  }
`;
