import { css } from "styled-components";
const width = css`
  width: 1080px;
`;
const flex = css`
  display: flex;
  align-items: center;
`;
const flexSb = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const flexSa = css`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
const flexSaBase = css`
  display: flex;
  align-items: baseline;
  justify-content: space-around;
`;

const handsomeNomal = css`
  font-family: "handsome-pro-classic", sans-serif;
  font-weight: 400;
  font-style: normal;
`;
const handsomeBold = css`
  font-family: "handsome-pro", sans-serif;
  font-weight: 700;
  font-style: normal;
`;
const textColor = css`
  color: white;
`;

export {
  flex,
  flexSb,
  flexSa,
  flexSaBase,
  width,
  handsomeNomal,
  handsomeBold,
  textColor,
};
