import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Header } from "./Header";
import { width, textColor, handsomeBold } from "../MixIn";
import workItems from "../workItems";

export const Work = () => {
  const { uniqueName } = useParams();
  const createList = workItems.filter(
    (work) => work.uniqueName === `${uniqueName}`
  );
  console.log(createList);
  // useStateに格納→スプレッド構文&filterで、配列以外のオブジェクトを排除→残ったオブジェクトをmapで出力

  return (
    <WorkContent>
      <Header />
      <div className="content">
        <div className="section">
          <div className="grid">
            <ul className="detail">
              {createList.map((work) => {
                return (
                  <>
                    <li>
                      <p className="title">{work.category}</p>
                      <hr />
                      <p className="description">{work.description}</p>
                      <p className="skills">{work.skill}</p>
                    </li>
                    <li>
                      <img src={`.${work.img01}`} className="img" />
                    </li>
                    <li>
                      <img src={`.${work.img02}`} className="img" />
                    </li>
                    <li>
                      <img src={`.${work.img03}`} className="img" />
                    </li>
                    <li>
                      <img src={`.${work.img04}`} className="img" />
                    </li>
                    <li className="sp">
                      <img src={`.${work.img05}`} className="img" />
                    </li>
                    <li className="sp">
                      <img src={`.${work.img06}`} className="img" />
                    </li>
                    <li className="sp">
                      <img src={`.${work.img07}`} className="img" />
                    </li>
                    <li className="sp">
                      <img src={`.${work.img08}`} className="img" />
                    </li>
                    <li className="buttonArea">
                      <a href={work.url} target="_blank">
                        <button className="more">View More</button>
                      </a>
                    </li>
                  </>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <FooterContent>
        <p>May the world be at peace tomorrow by shonanGold</p>
      </FooterContent>
    </WorkContent>
  );
};
const WorkContent = styled.div`
  background-image: url(../bg03.png);
  background-position: center center;
  background-repeat: repeat-y;
  background-size: contain;
  .content {
    padding: 50px 0 30px 0;
    .grid {
      ${width}
      height: 100%;
      margin: 0 auto;
      .detail {
        display: flex;
        flex-wrap: wrap;
        li {
          width: 100%;
          display: flex;
          justify-content: center;
          margin-bottom: 120px;
          &:first-child {
            display: flex;
            flex-direction: column;
            text-align: center;
            margin-bottom: 60px;
            .title {
              ${handsomeBold}
              font-size: 45px;
              ${textColor}
              margin: 0;
            }
            hr {
              background-color: white;
              width: 750px;
            }
            .description {
              ${textColor}
              font-size: 20px;
            }
            .skills {
              ${textColor}
              font-size: 16px;
            }
          }
          &:last-child {
            margin-bottom: 0%;
          }
          &.sp {
            width: 50%;
            img {
              width: 250px;
            }
          }
          img {
            width: 750px;
          }
        }
        .buttonArea {
          width: 100%;
          text-align: center;
          button {
            padding: 20px 0;
            border-radius: 10px;
            width: 300px;
            font-size: 35px;
            ${handsomeBold}
            ${textColor}
          background-color: darkgray;
            box-shadow: 2px 3px 6px silver;
            &:hover {
              transform: translate(2px, 3px);
              box-shadow: none;
              transition: 0.2s;
            }
          }
        }
      }
    }
  }
`;

const FooterContent = styled.footer`
  padding: 10px 0 30px 0;
  p {
    text-align: center;
    color: white;
    margin: 0;
  }
`;
