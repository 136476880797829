import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LaptopChromebookIcon from "@mui/icons-material/LaptopChromebook";
import EmailIcon from "@mui/icons-material/Email";
import { flexSa, flexSaBase, width, textColor } from "../MixIn";

export const Header = () => {
  return (
    <HeaderArea>
      <div className="grid">
        <nav>
          <Link to="/aboutus">
            <FavoriteIcon className="icon" />
          </Link>
          <Link to="/works">
            <LaptopChromebookIcon className="icon" />
          </Link>
        </nav>
        <Link to="/">
          <img src="../logo.png" alt="" />
        </Link>
        <nav>
          <Link to="/contact">
            <EmailIcon className="icon" />
          </Link>
          <img src="../btnJa.png" className="iconFlag" alt="" />
        </nav>
      </div>
    </HeaderArea>
  );
};

const HeaderArea = styled.header`
  width: 100%;
  height: 74px;
  padding-top: 10px;
  img {
    width: 95px;
    height: 60px;
  }
  nav {
    width: 440px;
    ${flexSa}
    .icon {
      padding: 6px;
      background-color: white;
      border-radius: 50%;
      color: gray;
      cursor: pointer;
    }
    .iconFlag {
      width: 36px;
      height: auto;
      cursor: pointer;
    }
  }
  .grid {
    ${width}
    height: 100%;
    margin: 0 auto;
    ${flexSaBase}
    padding: 0;
  }
`;
